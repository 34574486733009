//
// Custom Progress Bars
// --------------------------------------------------


// Linear Progress Bars
// --------------------------------------------------

.progress-linear {
  position: relative;
  height: $progress-linear-height;

  .progress-bar-linear-wrap, .progress-bar-linear {
    height: 100%;
    border-radius: $progress-bar-linear-border-radius;
  }

  .progress-bar-linear-wrap {
    background: $progress-bar-linear-empty-background;
  }

  .progress-bar-linear {
    width: 0;
    @include transition(.5s all ease);
  }

  .progress-value,
  .progress-size{
    &:after{
      content:"%";
    }
  }
}

// Circle Progress Bars
// --------------------------------------------------

.progress-bar-circle {
  display: inline-block;
  position: relative;
  text-align: center;
  line-height: 1.2;
  font-family: $font-family-accent;
  letter-spacing: 0.04em;


  canvas {
    vertical-align: middle;
  }

  span {
    position: absolute;
    top: 51%;
    left: 52%;
    font-weight: $progress-bar-circle-weight;
    font-size: $progress-bar-circle-size;
    line-height: $progress-bar-circle-line-height;
    transform: translate(-50%, -50%);
    color: $brand-primary;
    font-family: $font-family-base;
    &:after {
      content: $progress-bar-circle-icon;
    }
  }
  &.progress-bar-modern{
    span {
      color: $gray-dark;
    }
  }
}
