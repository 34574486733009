//
// Breadcrumb custom
// --------------------------------------------------

// Breadcrumb Classic
// -------------------------

.breadcrumb-classic {
  background: #890013;
  position: relative;
  .list-inline-dashed {
    > li {
      &:last-child{
       color: rgba(255,255,255,.4);
        &:before{
          color: $white;
        }
      }
    }
  }
}



