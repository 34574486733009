//
// RD Navbar Default Panel
// --------------------------------------------------

%rd-navbar-default-panel {
  .rd-navbar-panel {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 16;
    background: $rd-navbar-light-background;
    box-shadow: nth($shadow-area-sm, 1) nth($shadow-area-sm, 2) nth($shadow-area-sm, 3) nth($shadow-area-sm, 4) rgba($gray-darker, .35);
  }
  &, .rd-navbar-panel {
    height: $rd-navbar-fixed-height;
  }
  .rd-navbar-cart{
    display: none;
  }
  .rd-navbar-toggle {
    position: fixed;
    z-index: 17;
    top: 3px;
    left: 3px;
    @include toggle-icons-via-rotation(50px, 28px, $brand-primary, '\f0c9', '\f00d', 'FontAwesome', 'FontAwesome');
    span {
      background-color: transparent;
      &:before,
      &:after {
        background-color: transparent;
      }
    }

  }
  .rd-navbar-top-panel-toggle {
    @include toggle-icons-via-rotation(48px, 30px, $gray-base, "\f2ce", "\f24c");
    position: absolute;
    vertical-align: middle;
    z-index: 17;
    top: 4px;
    display: inline-block;
    width: 48px;
    height: 48px;
    font-size: 24px;
    line-height: 48px;
    text-align: center;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    padding: 0;
    right: 10px;
  }
  .rd-navbar-top-panel {
    position: fixed;
    top: 56px;
    right: 0;
    background: $white;
    padding: 15px;
    opacity: 0;
    visibility: hidden;
    box-shadow: $shadow-area-xs;
    @include transform(translateX(100%));
    transition: 0.35s all ease;
    max-width: 260px;
    &.active {
      opacity: 1;
      visibility: visible;
      @include transform(translateX(0));
    }
    .icon {
      font-size: 18px;
      line-height: 18px;
    }
    li {
      & + li {
        margin-top: 8px;
      }
    }
    a:hover {
      color: $brand-primary;
    }
    &-left-part {
      + .rd-navbar-top-panel-right-part {
        margin-top: 8px;
      }
    }
  }
  .panel-title {
    position: absolute;
    left: 50%;
    @include transform(translateX(-50%));
    top: 0;
    line-height: 56px;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 18px;
    color: $brand-madison;
  }

}
