/*==================  Material Parallax    ======================*/
.parallax-container {
  position: relative;
  overflow: hidden;
}

.material-parallax {
  position: absolute;
  top: 0;
  left: -1px;
  right: -1px;
  bottom: 0;
  z-index: 0;
}

.parallax-container * {
  color: #fff;
}

.material-parallax img {
  display: none;
  position: absolute;
  left: 50%;
  bottom: 0;
  min-width: 101%;
  min-height: 101%;
  margin-left: -1px;
  transform: translate3d(-50%, 0, 0);
}

[data-x-mode='true'] .material-parallax img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}

.material-parallax .parallax-image-stretch {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  max-width: none;
}

@supports(object-fit: cover) {
  .material-parallax .parallax-image-stretch {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
.parallax-content {
  position: relative;
  z-index: 1;
}

.ipad, .iphone {
  .parallax-container {
    background-attachment: scroll !important;
  }
}

