//
// Custom Thumbnails
// --------------------------------------------------

// Base styles
// --------------------------------------------------

%thumbnail {
  &,
  > figure {
    position: relative;
    display: block;
  }

  img,
  figure img {
    width: 100%;
    height: auto;
    transition: .35s;
    will-change: transform;
  }

  figure figcaption,
  figcaption {
    position: absolute;
  }
}

// Bootstrap Thumbnail
// -------------------------

.thumbnail {
  transition: .3s;
  border: none;
  box-shadow: $shadow-area-md;
}

a.thumbnail:hover {
  box-shadow: $shadow-area-lg;
}

// Thumbnail Classic
// -------------------------

.thumbnail-classic {
  @extend %thumbnail;
  position: relative;
  overflow: hidden;
  figure figcaption,
  figcaption {
    @include display-flex();
    @include flex-direction(column);
    @include justify-content(center);
    position: static;
    background: $brand-madison;
    color: #d3d3d3;
    padding: 15px;
  }
  &-img-wrap {
    position: relative;
  }
  .gallery-link-mobile {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
  &-title {
    color: $white;
  }
  .divider {
    background: $white;
    margin: 10px auto 15px;
  }
  .icon {
    font-size: 24px;
    line-height: 24px;
    color: $white;
    &:hover{
      color: $brand-primary;
    }
  }
  @media (min-width: $screen-lg-min) {
    figure figcaption,
    figcaption {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      padding-left: 15px;
      padding-right: 15px;
      background: none;
      z-index: 2;
      opacity: 0;
      visibility: hidden;
      transition: 0.35s all ease-out;
      @include transform(translateY(-10%));
      &:before {
        position: absolute;
        left: 10px;
        right: 10px;
        top: 10px;
        bottom: 10px;
        content: "";
        transition: 0.35s all ease-out;
      }
      p{
        max-height: 45px;
        overflow: hidden;
      }
    }
    &:before {
      position: absolute;
      left: -20px;
      right: -20px;
      top: -20px;
      bottom: -20px;
      content: "";
      background: rgba($brand-madison, .8);
      z-index: 1;
      opacity: 0;
      visibility: hidden;
      transition: 0.35s all ease-out;
      transition-delay: 0.2s;
    }
    &:hover{
      &:before{
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
      }
      figure figcaption,
      figcaption {
        opacity: 1;
        visibility: visible;
        @include transform(translateY(0));
        &:before{
          left: 20px;
          right: 20px;
          top: 20px;
          bottom: 20px;
        }
      }
    }
  }
  @media (min-width: $screen-xl-min) {
    .divider {
      margin: 10px auto 33px;
    }
    figure figcaption,
    figcaption {
      padding-left: 50px;
      padding-right: 50px;
      &:before {
        border: 1px solid $white;
      }
      max-height: none;
    }
  }

}


// Thumbnail Classic
// -------------------------
.thumbnail-default {
  position: relative;
  display: inline-block;
  width: 100%;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#000, .5);
    opacity: 0;
    transition: 0.35s all ease;
    z-index: 1;
  }
  .icon {
    position: absolute;
    left: 50%;
    top: 100%;
    @include transform(translate(-50%, -50%));
    opacity: 0;
    font-size: 30px;
    color: $white;
    transition: 0.35s all ease;
    z-index: 2;
  }
}

.ie-11, .ie-edge {
  .thumbnail-default {
    display: block;
  }
  .widget-flickrfeed{
    .thumbnail-default {
      display: inline-block;
    }
  }
}

.tablet .thumbnail-default,
.mobile .thumbnail-default,
.thumbnail-default:hover {
  .icon {
    top: 50%;
    opacity: 1;
  }
  &:after {
    opacity: 1;
  }
}

.tablet .thumbnail-default,
.mobile .thumbnail-default {
  &:after {
    opacity: .4;
  }
}
.widget-flickrfeed{
  .thumbnail-default {
    width: 50%;
  }
}


//
//  Thumbnail Modern
//
.thumbnail-modern {
  display: block;
  position: relative;
  z-index: 1;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 0;
  background-color: transparent;
}

.thumbnail-variant-4 {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  box-shadow: 0 0 13px 0 rgba(1, 3, 4, 0.15);
}

@media (max-width: 575px) {
  .thumbnail-variant-4 {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}

.thumbnail-variant-4 .thumbnail-figure {
  background: #000;
  img {
    width: 100%;
    height: auto;
    max-width: none;
  }
}

.thumbnail-variant-4 .thumbnail-image-wrap {
  opacity: .96;
}

.thumbnail-variant-4 .caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 16px 15px;
  text-align: center;
  color: #000;
  background: #fff;
}

.thumbnail-variant-4 .thumbnail-text-light {
  color: #000b24;
}

@media (min-width: 992px) {
  html:not(.tablet):not(.mobile) .thumbnail-variant-4 .thumbnail-image-wrap {
    position: relative;
    will-change: transform;
    opacity: 1;
    transition: opacity .7s, -webkit-transform .7s;
    transition: opacity .7s, transform .7s;
    transition: opacity .7s, transform .7s, -webkit-transform .7s;
    -webkit-transform: scale3d(1.001, 1.001, 1);
    transform: scale3d(1.001, 1.001, 1);
  }
  html:not(.tablet):not(.mobile) .thumbnail-variant-4 .caption,
  html:not(.tablet):not(.mobile) .thumbnail-variant-4 .caption-header {
    transition: -webkit-transform 0.55s;
    transition: transform 0.55s;
    transition: transform 0.55s, -webkit-transform 0.55s;
    -webkit-transform: translate3d(0, 200%, 0);
    transform: translate3d(0, 200%, 0);
  }
  html:not(.tablet):not(.mobile) .thumbnail-variant-4 .caption-header {
    transition-delay: 0.05s;
  }
  html:not(.tablet):not(.mobile) .thumbnail-variant-4:hover .thumbnail-image-wrap {
    opacity: .9;
    -webkit-transform: scale3d(1.07, 1.07, 1);
    transform: scale3d(1.07, 1.07, 1);
  }
  html:not(.tablet):not(.mobile) .thumbnail-variant-4:hover .caption,
  html:not(.tablet):not(.mobile) .thumbnail-variant-4:hover .caption-header {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@media (min-width: 768px) {
  .thumbnail-variant-4 h4 {
    font-size: 22px;
  }
}

@media (min-width: 992px) {
  .thumbnail-variant-4 .caption {
    padding: 17px 15px;
  }
}


