//
// Filled Icons
// --------------------------------------------------

@mixin filled-icon($parent, $background-color, $text-color) {
  #{$parent} {
    color: $text-color;
    @extend #{$background-color};
    @content;

    &.icon-outlined {
      &:after {
        @extend #{$background-color};
      }
    }
  }
}
@mixin gradient-directional-three-colors($start-color: #555, $mid-color: #333, $deg: 45deg,$color-stop: 50%, $end-color: #c3325f) {
  background-image: -webkit-linear-gradient($deg, $start-color, $mid-color $color-stop, $end-color); // Safari 5.1-6, Chrome 10+
  background-image: -o-linear-gradient($deg, $start-color, $mid-color $color-stop, $end-color); // Opera 12
  background-image: linear-gradient($deg, $start-color, $mid-color $color-stop, $end-color); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1); // IE9 and down, gets no color-stop at all for proper fallback
}