//
// Pricing and Plans
// --------------------------------------------------

// Box Pricing
// -------------------------

.box-pricing {
  text-align: left;

  &-title {
    @include display-flex();
    @include flex(0 1 100%);
    @include flex-direction(row);
    @include flex-wrap(nowrap);

    > *, &:after {
      @include flex(0 1 auto);
      @include flex-grow(0);
    }

    .box-pricing-price {
      margin-top: 0;
    }

    .box-pricing-name {
      padding-top: 5px;
    }

    .box-pricing-dots {
      position: relative;
      @include flex-grow(1);
      top: -6px;
      left: -2px;
      //content: '';
      border-bottom: 2px dotted $gray-darker;
    }
  }

  // List offsets

  li + li {
    margin-top: 23px;
  }
}

// Box Planning
// -------------------------

.box-planning {
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  &-header {
    position: relative;
    text-align: center;
    background: $brand-madison;
    color: $white;
    padding: 34px 5px;
    padding-bottom: 28px;
    p {
      .h1, .h2, .h3, .h4, .h5 {
        letter-spacing: 0;
      }
    }
    &:before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      left: 50%;
      margin-left: -6px;
      bottom: -6px;
      border-style: solid;
      border-width: 6px 6px 0 6px;
      border-color: $brand-madison transparent transparent transparent;
    }
  }

  .list-marked {
    li + li {
      margin-top: 11px;
    }
  }
  .box-planning-body {
    padding: 12% 3% 12% 3%;
    background: $white;
  }

  &.active {
    box-shadow: $shadow-area-sm;
    .box-planning-header {
      background: $brand-primary;
      &:before {
        border-color: $brand-primary transparent transparent transparent;
      }
    }
    .btn-primary {
      @include button-variant-custom($white, $brand-madison, $brand-madison, $white, $brand-madison, $brand-madison);
    }
  }
}

.box-offer{
  background: $gray-lightest;
  padding: 12%;
  p {
    .h1, .h2, .h3, .h4, .h5 {
      letter-spacing: 0;
    }
  }
  &.active {
    background: $white;
    box-shadow: $shadow-area-sm;
    .btn-primary {
      @include button-variant-custom($white, $brand-madison, $brand-madison, $white, $brand-madison, $brand-madison);
    }
  }
}