//
// RD Navbar Sidebar Toggle Panel
// --------------------------------------------------

%rd-navbar-sidebar-toggle-panel{
  .rd-navbar-panel{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 16;
  }
  .rd-navbar-panel-title{
    position: absolute;
    display: block;
    text-align: center;
    right: 15px;
    h4{
      display: inline-block;
      line-height: 56px;
      font-weight: 700;
      font-size: 16px;
      letter-spacing: 0;
      min-width: 255px;
      @media (min-width: $screen-xs-min) {
        font-size: $font-size-h4;

      }
    }
    @media (min-width: $screen-xs-min) {
      left: 50%;
      right: auto;
      transform: translateX(-50%);
    }
  }
  &, .rd-navbar-panel{
    height: $rd-navbar-fixed-height;
  }

  .rd-navbar-toggle {
    position: fixed;
    z-index: 17;
    left: 3px;
    top: 3px;
    @extend %rd-navbar-transition;
  }

  @media (min-width: $screen-lg) {
    .rd-navbar-panel{
      position: static;
    }
    &, .rd-navbar-panel{
      height: 0;
    }
    .rd-navbar-toggle{
      background: none;
      border: none;
      outline: none;
      padding: 0;
      top: 10px;
      left: 10px;
      display: block;
      z-index: 22;
      @include toggle-icons-via-rotation($rd-navbar-sidebar-toggle-panel-lg-toggle-width, $rd-navbar-sidebar-toggle-panel-lg-toggle-size, $rd-navbar-sidebar-toggle-panel-lg-toggle-color, $rd-navbar-sidebar-toggle-panel-lg-toggle-icon-state-before, $rd-navbar-sidebar-toggle-panel-lg-toggle-icon-state-after, $rd-navbar-sidebar-toggle-panel-lg-toggle-icon-state-family, $rd-navbar-sidebar-toggle-panel-lg-toggle-icon-state-family);
      span{
        background: $brand-primary;
      }
      &.active{
        span{
          background: transparent;
        }
      }
    }
  }
}

  .rd-navbar-panel {
    background: $rd-navbar-light-sidebar-toggle-panel-background;
    box-shadow: $shadow-area-xs;
  }
  .rd-navbar-toggle {
    color: $rd-navbar-light-sidebar-toggle-panel-color;
  }

  @media (max-width: $screen-lg - 1) {
    .rd-navbar-toggle{
      @include toggle-lines-to-arrow($rd-navbar-light-sidebar-toggle-panel-lg-toggle-width, $rd-navbar-light-sidebar-toggle-panel-lg-toggle-size, $rd-navbar-light-sidebar-toggle-panel-lg-toggle-color);
    }
  }

  @media (min-width: $screen-lg) {
    .rd-navbar-panel{
      background-color: transparent;
      box-shadow: none;
    }
  }
