//
// RD Navbar Default
// --------------------------------------------------

.rd-navbar-transparent {
  @extend %rd-navbar-transition;
  &.rd-navbar-fixed {
    background: $white;
    @extend %rd-navbar-default-panel;
    @extend %rd-navbar-default-mobile-menu;
    @extend %rd-navbar-default-aside-left;
    @extend %rd-navbar-default-mobile-search;
    .rd-navbar-brand {
      display: none;
    }
    .rd-navbar-search {
      display: none;
    }
    @media (min-width: $screen-lg) {
      .rd-navbar-mobile-brand {
        display: none;
      }
      .rd-navbar-brand {
        display: block;
      }
    }
  }

  &.rd-navbar-static {
    @extend %rd-navbar-default-menu-first-lvl;
    @extend %rd-navbar-default-menu-dropdown;
    @extend %rd-navbar-default-search;
    .rd-navbar-mobile-brand {
      display: none;
    }
    .rd-navbar-search-toggle {
      @include toggle-icons-via-rotation($rd-navbar-search-toggle-icon-size, $rd-navbar-search-toggle-icon-size, $white, $rd-navbar-search-toggle-open-icon, $rd-navbar-search-toggle-close-icon, "FontAwesome", "FontAwesome");
      span{
        transition: none;
      }
    }
    .rd-navbar-dropdown {
      margin-top: 28px;
    }
    .rd-navbar-nav {
      > li {
        > a {
          color: $white;
        }
        &.active > a, &.focus > a, > a:hover {
          color: $white;
          text-decoration: underline;
        }
      }
      @media (max-width: $screen-xl-min) {
        > li + li{
          margin-left: 25px;
        }
      }
    }

    .rd-navbar-search-form {
      margin-top: 22px;
    }
    .rd-navbar-brand {
      color: $white;
      float: left;
      img {
        width: 54px;
        height: 54px;
        transition: 0.25s all ease;
      }
      &-title {
        font-size: 20px;
        line-height: 1.2;
        font-family: $font-family-accent;
      }
      &-slogan {
        font-size: 10px;
        font-style: italic;
        font-weight: 300;
        color: $white;
        font-family: $font-family-accent;
      }
      .wrap{
        background: $white;
        border-radius: 50%;
        padding: 13px;
      }
      @media (min-width: $screen-xl-min) {
        &-title {
          font-size: 24px;
          font-weight: 700;
        }
        &-slogan {
          font-size: 14px;
        }
      }
    }

    .rd-navbar-panel .rd-navbar-toggle, .form-search-wrap {
      display: none;
    }
    // navbar nav styles
    .rd-navbar-nav-wrap {
      display: inline-block;
      margin-top: 30px;
    }
    .rd-navbar-menu-wrap {
      padding:  15px 25px 0;
      text-align: right;
      @media (min-width: $screen-xl-min) {
        padding: 40px 65px 0;
      }
    }
    .rd-navbar-cart {
      display: inline-block;
      position: relative;
      top: -4px;
      margin-left: 25px;
      .icon{
        font-size: 18px;
        color: $white;
      }
      a{
        color: $white;
        font-family: $font-family-accent;
        font-weight: 700;
        &:hover{
          color: $brand-primary;
        }
      }
      @media (min-width: $screen-xl-min) {
        margin-left: 55px;
      }
    }
    .rd-navbar-search{
      margin-left: 20px;
      @media (min-width: $screen-xl-min) {
        margin-left: 38px;
      }
    }
    .rd-navbar-inner {
      @include clearfix;
      transition: .3s all ease;
      position: relative;
      text-align: left;
    }

    &.rd-navbar--is-stuck {
      background: $brand-madison;
      box-shadow: $shadow-area-md;
      .rd-navbar-top-panel{
        display: none;
      }
      .rd-navbar-brand{
        display: none;
      }
      .rd-navbar-menu-wrap{
        padding: 8px 20px;
        text-align: center;
      }
      .rd-navbar-nav-wrap{
        margin-top: 13px;
      }
      .rd-navbar-dropdown{
        margin-top: 23px;
      }
      .rd-navbar-search-form{
        margin-top: 17px;
      }
    }
  }
}