//
// Easy Responsive Tabs
// --------------------------------------------------

// Classic Tabs
// --------------------------------------------------

.responsive-tabs {
  // Collapse Tab
  .resp-accordion {
    position: $accordion-arrow-icon-position;
    padding: $accordion-accordion-inset;
    overflow: hidden;
    font-size: $accordion-size;
    font-weight: $accordion-weight;
    color: $accordion-color;
    text-align: left;
    font-family: $font-family-accent;
    text-overflow: ellipsis;
    transition: all .35s ease;
    border-top: 1px solid $gray-light;

    .resp-arrow {
      position: absolute;
      top: 50%;
      @include transform(translateY(-50%));
      right: 5px;
      width: $accordion-arrow-icon-width;
      font-family: $accordion-arrow-icon-family;
      font-size: 18px;
      font-weight: $accordion-arrow-icon-weight;
      color: $brand-madison;
      text-align: center;
      transition: all .35s ease;
      &:before {
        content: $accordion-arrow-icon;
      }
      @media (min-width: $screen-md-min) {
        font-size: 28px;
        right: 22px;
      }
    }

    // Active tab accordion
    &.resp-tab-active,
    &:hover {
      color: $accordion-active-color;
      cursor: pointer;
    }
    &.resp-tab-active {
      .resp-arrow {
        &:before {
          content: $accordion-arrow-icon-active;
        }
      }
    }
  }

  // Accordion Content
  .resp-tab-content {
    padding: 0 20px 20px;
    text-align: left;
  }

  // Tabs List
  .resp-tabs-list {
    display: none;
    padding: 0;
    margin-bottom: 0;

    li {
      position: relative;
      display: inline-block;
      padding: $accordion-list-inset;
      font-size: $accordion-list-size;
      font-weight: $accordion-list-weight;
      color: $accordion-list-color;
      text-transform: uppercase;
      list-style-type: none;
      cursor: pointer;
      transition: all .2s ease-in;

      &.resp-tab-active,
      &:hover {
        color: $accordion-list-active-color;
        background: transparent;
      }
    }
  }
  // Tab Content
  .resp-tab-content {
    display: none;
  }

  // Turn accordion to Horizontal Tabs
  @media (min-width: $screen-md-min) {
    &[data-type="horizontal"]:not(.tabs-lg-collapsed),
    &[data-type="vertical"]:not(.tabs-lg-collapsed) {
      border-bottom: $accordion-md-border-bottom;

      .resp-tab-content + .resp-accordion {
        border-top: $accordion-md-border-top;
      }

      .resp-accordion {
        display: none;
      }

      .resp-tabs-list {
        display: block;
      }
    }
  }

  // Style for vertical tabs
  @media (min-width: $screen-lg-min) {
    &[data-type="vertical"] {
      @include display-flex();
      @include flex(0 1 auto);
      @include align-items(flex-start);

      .resp-tabs-list {
        text-align: left;
        @include flex-basis(29%);

        li {
          display: block;
        }

        li + li {
          margin-left: 0;
        }
      }

      .resp-tabs-container {
        @include flex-basis(71%);
      }
    }
    &.tabs-lg-collapsed {
      border-bottom: $accordion-md-border-bottom;

      .resp-tab-content + .resp-accordion {
        border-top: $accordion-md-border-top;
      }

      .resp-accordion {
        display: none;
      }

      .resp-tabs-list {
        display: block;
      }
    }
  }
  border-bottom: 1px solid $gray-light;
}

// Classic Tabs
// --------------------------------------------------

.responsive-tabs-classic {

  @media (min-width: $screen-md-min) {
    &[data-type="vertical"],
    &[data-type="horizontal"] {
      border-bottom: $accordion-tabs-list-border-bottom;

      .resp-tab-content + .resp-accordion {
        border-top: $accordion-tabs-list-border-top;
        margin-top: 10px;
      }

      .resp-tabs-list {
        font-size: 0;
        text-align: left;
        position: relative;
        &:after {
          width: 100%;
          height: 1px;
          content: "";
          background: $gray-light;
          position: absolute;
          left: 0;
          top: 100%;
          z-index: 0;
        }
        li {
          text-transform: capitalize;
          padding-top: 10px;
          padding-bottom: 10px;
          font-weight: 400;
          font-family: $font-family-base;
          font-size: 20px;
          position: relative;
          &:after {
            position: absolute;
            content: "";
            width: 0;
            height: 1px;
            top: 100%;
            left: 50%;
            z-index: 2;
            transition: 0.35s all ease-in;
          }
          &.resp-tab-active,
          &:hover {
            color: $brand-primary;
            border-left-color: transparent;
            &:after {
              width: 100%;
              left: 0;
              background: $brand-primary;
            }
          }
          & + li {
            margin-left: 48px;
          }
        }
      }

      // Tab Content
      .resp-tab-content {
        padding: 30px 0 0 0;
      }
      .tabs-lg-collapsed{
        // Tab Content
        .resp-tab-content {
          padding: 0 20px 20px;
        }
        .resp-tab-content + .resp-accordion {
          border-top: 1px solid $gray-light;
          margin-top: 0;
        }
      }
      &.tabs-lg-collapsed{
        border-bottom: 1px solid $gray-light;
      }
    }
  }

  // Turn Horizontal Tabs to Vertical Tabs
  @media (min-width: $screen-lg-min) {

    // Style for vertical tabs
    &[data-type="vertical"] {
      .resp-tabs-list {
        text-align: right;
        li + li {
          margin-left: 0;
          margin-top: 17px;
        }
        li {
          padding: 0 20px;
          &:after {
            height: 0;
            top: 50%;
            left: 100%;
            margin-left: -1px;
          }
          &.resp-tab-active,
          &:hover {
            &:after {
              width: 1px;
              left: 100%;
              height: 100%;
              top: 0;
              background: $brand-primary;
            }
          }
        }
        &:after {
          left: auto;
          right: 0;
          width: 1px;
          height: 100%;
          top: 0;
        }
      }
      .resp-tab-content {
        padding: 0;
      }
      .tabs-lg-collapsed{
        // Tab Content
        .resp-tab-content {
          padding: 0;
        }
      }
      &.tabs-lg-collapsed{
        border-bottom: none;
      }
    }
  }
}
.tablet{
  .responsive-tabs-classic {
    @media (min-width: $screen-md-min) {
      &[data-type="vertical"],
      &[data-type="horizontal"] {
        .resp-tabs-list {
          li {
            &:hover {
              color: #2d2e2e;
              border-left-color: transparent;
              &:after {
                width: 0;
                left: 0;
                background: $brand-primary;
              }
            }
            &.resp-tab-active {
              color: $brand-primary;
              border-left-color: transparent;
              &:after {
                width: 100%;
                left: 0;
                background: $brand-primary;
              }
            }
          }
        }
      }
    }
  }
}


// Boxed Tabs
// --------------------------------------------------

.responsive-tabs-boxed {
  // Collapse Tab
  .resp-accordion {
    border-radius: $border-radius-small;
    border: $accordion-tabs-boxed-border;
    padding-left: $accordion-tabs-boxed-inset-left;
    padding-right: $accordion-tabs-boxed-inset-right;

    .resp-arrow {
      right: $accordion-tabs-boxed-arrow-position-right;
    }
  }

  // Accordion Content
  .resp-tab-content {
    padding: 30px 0;
    text-align: left;
  }

  .resp-tab-content + .resp-accordion {
    margin-top: $accordion-tabs-boxed-content-offset-top;
  }

  // Turn accordion to Horizontal Tabs
  @media (min-width: $screen-md-min) {
    &[data-type="horizontal"],
    &[data-type="vertical"] {
      .resp-tabs-list {
        text-align: left;
        font-size: 0;
        li {
          text-transform: capitalize;
          position: relative;
          padding: $accordion-tabs-boxed-list-inset;
          margin-bottom: -1px;
          font-size: $accordion-tabs-boxed-list-size;
          font-weight: $accordion-tabs-boxed-list-weight;
          color: $accordion-tabs-boxed-list-letter-color;
          border: $accordion-tabs-boxed-list-md-li-border;
          border-radius: $border-radius-base;
          & + li {
            margin-left: 30px;
          }
          &.resp-tab-active,
          &:hover {
            background: $brand-primary;
            color: $white;
            border-color: $brand-primary;
          }
        }
      }
      .resp-tab-content {
        padding-bottom: 0;
      }
      .resp-tab-content + .resp-accordion {
        margin-top: $accordion-tabs-boxed-list-md-offset-top;
      }
    }

    // Turn Horizontal Tabs to Vertical Tabs
    @media (min-width: $screen-lg-min) {
      &[data-type="vertical"] {
        .resp-tabs-list {
          padding-right: 30px;
          text-align: left;
          margin: $accordion-tabs-boxed-list-lg-offsets;
          li {
            & + li {
              margin-left: 0;
              margin-top: 20px;
            }

            &.resp-tab-active {
            }
          }
        }
        .resp-tab-content {
          padding-top: 0;
          padding-bottom: 0;
          padding-left: 30px;
          padding-right: 30px;
        }
      }
    }
  }
}

.accordion-modern {
  .resp-accordion,
  .resp-tab-content {
    background: $gray-lighter;
    border-top: none;
    padding-left: 60px;
    padding-right: 20px;
    .resp-arrow {
      left: 19px;
      font-size: 18px;
      color: $brand-primary;
      border-radius: $border-radius-base;
      &:before {
        content: "\f505";
      }
      @media (min-width: $screen-md-min) {
        font-size: 28px;
      }
    }
    // Active tab accordion
    &.resp-tab-active,
    &:hover {
      color: $gray-base;
      cursor: pointer;
    }
    &.resp-tab-active {
      .resp-arrow {
        &:before {
          content: "\f466";
        }
      }
    }
  }
  .resp-accordion{
    color: $gray-base;
  }
  .resp-tab-content + .accordion-modern {
    margin-top: 10px;
  }
  border-bottom: none;
}

