//
// Offsets
// --------------------------------------------------


// Insets
// -------------------------

$insets: (0, 10px, 15px, 20px, 25px, 30px, 38px, 50px, 60px, 70px, 100px, 110px);

html .page{
  @include indent-responsive(inset, left, padding-left, $medias, $insets);
  @include indent-responsive(inset, right, padding-right, $medias, $insets);
}


// Elements
// -------------------------

.inset-left-7p{
  padding-left: 7%;
}
.inset-right-7p{
  padding-right: 7%;
}

.inset-left-11p{
  padding-left: 11%;
}

.inset-right-11p{
  padding-right: 11%;
}


// Grid
// -------------------------


// Sections
// -------------------------


// Responsive
// -------------------------

$offsets: (0, 5px, 10px, 12px, 15px, 17px, 20px, 22px, 25px, 27px, 30px, 35px, 40px, 45px, 47px, 50px, 56px, 60px, 65px, 70px, 85px, 90px, 110px, 114px, 133px, 147px);

html .page{
  @include indent-responsive(offset, top, margin-top, $medias, $offsets);
}
