//
// RD Navbar Default
// --------------------------------------------------

.rd-navbar-center {
  @extend %rd-navbar-transition;
  background: $white;
  &.rd-navbar-fixed {
    @extend %rd-navbar-default-panel;
    @extend %rd-navbar-default-mobile-menu;
    @extend %rd-navbar-default-aside-left;
    @extend %rd-navbar-default-mobile-search;
    .rd-navbar-brand {
      display: none;
    }
    .rd-navbar-search {
      display: none;
    }
    .rd-navbar-top-panel{
      padding: 30px 15px;
      .shell{
        min-width: 250px;
      }
    }
    @media (min-width: $screen-md) {
      .rd-navbar-mobile-brand {
        display: none;
      }
      .rd-navbar-brand {
        display: block;
      }
    }
  }

  &.rd-navbar-static {
    @extend %rd-navbar-default-menu-first-lvl;
    @extend %rd-navbar-default-menu-dropdown;
    @extend %rd-navbar-default-search;
    // Panel style

    .rd-navbar-mobile-brand {
      display: none;
    }
    .rd-navbar-dropdown, .rd-navbar-megamenu{
      @media (min-width: $screen-md-min) {
        max-height: calc(100vh - 283px);
        overflow-y: auto;
      }
    }
    .rd-navbar-megamenu{
      @media (min-width: $screen-md-min) {
        width: 97%;
      }
      @media (min-width: $screen-lg-min) {
        width: 100%;
      }
    }
    .rd-navbar-top-panel {
      color: $gray-dark;
      .shell{
        padding: 30px 0;
        border-bottom: 1px solid $gray-light;
      }
      .icon{
        color: $gray-base;
        font-size: 24px;
        line-height: 24px;
      }
      a:hover{
        color: $brand-primary;
      }

      .rd-navbar-brand {
        a:hover{
          opacity: 1;
          color: $gray-base;
        }
        color: $gray-base;
        img {
          width: 89px;
          height: 91px;
          transition: 0.25s all ease;
        }
        &-title {
          font-size: 24px;
          line-height: 1.2;
          font-family: $font-family-accent;
          font-weight: 700;
        }
        &-slogan {
          font-size: 18px;
          font-style: italic;
          font-weight: 300;
          color: $gray-dark;
          font-family: $font-family-accent;
        }
      }
    }

    .rd-navbar-panel .rd-navbar-toggle, .form-search-wrap {
      display: none;
    }
    .rd-navbar-panel{
      box-shadow: none;
    }
    // navbar nav styles
    .rd-navbar-nav-wrap {
      display: inline-block;
    }
    .rd-navbar-menu-wrap {
      padding: 16px;
      text-align: center;
    }
    .rd-navbar-cart {
      display: inline-block;
      position: relative;
      top: -4px;
      margin-left: 55px;
      .icon{
        font-size: 18px;
        color: $gray-base;
      }
      a{
        color: $gray-dark;
        font-family: $font-family-accent;
        font-weight: 700;
        &:hover{
          color: $brand-primary;
        }
      }
    }
    .rd-navbar-search{
      margin-left: 45px;
    }
    .rd-navbar-inner {
      @include clearfix;
      transition: .3s all ease;
      position: relative;
      text-align: left;
    }
    .rd-navbar-dropdown{
      margin-top: 31px;
    }
    .rd-navbar-search-form{
      margin-top: 25px;
    }
    &.rd-navbar--is-stuck {
      box-shadow: $shadow-area-md;
      .rd-navbar-top-panel{
        display: none;
      }
      .rd-navbar-menu-wrap{
        padding: 7px 20px;
      }
      .rd-navbar-dropdown, .rd-navbar-megamenu{
        max-height: 100vh;
      }
      .rd-navbar-brand{
        display: none;
      }
      .rd-navbar-nav-wrap{
        margin-top: 10px;
      }
      .rd-navbar-dropdown{
        margin-top: 22px;
      }
      .rd-navbar-search-form{
        margin-top: 16px;
      }
    }
  }
}